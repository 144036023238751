export default {
  isAuthenticated: state => {
    return state.user.token !== null
  },

  getWindowWidth: state => {
    return state.windowWidth
  },

  playerResultId: state => {
    return state.user.playerResultId
  },

  gameplay: state => {
    return state.gameplay
  },

  gameplayProfile: state => {
    return state.gameplay ? state.gameplay.gameplayProfile : null
  },

  softSkills: state => {
    return state.gameplay ? state.gameplay.softSkills : []
  },

  softSkillsInvited: state => {
    return state.gameplay ? state.gameplay.softSkillsInvited : []
  },

  totalQuestionsCounter: (state, getters) => {
    const softSkills = getters.softSkills

    let sum = 0

    softSkills.forEach(skill => {
      sum += skill.questionCount
    })

    return sum
  },

  hasStarted: state => {
    return (state.gameplay && state.gameplay.gameplayProfile.currentQuestion) || state.started
  },

  softSkillIndex: (state, getters) => {
    const softSkills = getters.softSkills
    let softSkillIndex = 0

    for (softSkillIndex; softSkillIndex < softSkills.length; softSkillIndex++) {
      const softSkill = softSkills[softSkillIndex]

      if (softSkill.status !== 'FINISHED') {
        for (let questionIndex = 0; questionIndex < softSkill.questions.length; questionIndex++) {
          const question = softSkill.questions[questionIndex]

          if (!question.answered) {
            break
          }
        }
        break
      }
    }

    return softSkillIndex
  },

  justFinishedInvitation: (state, getters) => {
    const softSkills = getters.softSkills
    const softSkillsInvited = getters.softSkillsInvited
    const softSkillIndex = getters.softSkillIndex
    return softSkills.length > softSkillsInvited.length && softSkillIndex === softSkillsInvited.length
  },

  isCompleted: (state, getters) => {
    const softSkills = getters.softSkills
    const pendingSkills = softSkills.filter(skill => skill.status !== 'FINISHED')
    return pendingSkills.length === 0
  },

  langKey: state => {
    return state.langKey
  }
}
