import store from '@/store/store'

const mixins = {
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },

    getActiveComponent() {
      return this.$route.name
    },

    staticFolder() {
      return `/${process.env.staticFolder}`
    },

    $gutter() {
      return 16 // Grid column gutter
    },

    $windowWidth: {
      get: function() {
        return store.getters.getWindowWidth
      },
      set: function(val) {
        store.commit('setWindowWidth', val)
      }
    },

    breakpoints() {
      return {
        'xxs': 360,
        'xs': 767,
        'sm': 768,
        'md': 992,
        'lg': 1200,
        'xl': 1920
      }
    },

    isMobile() {
      return this.breakpoints.xs >= this.$windowWidth
    },

    isTablet() {
      return (
        this.breakpoints.xs < this.$windowWidth &&
        this.$windowWidth <= this.breakpoints.sm
      )
    },

    isDesktop() {
      return this.breakpoints.sm < this.$windowWidth
    }
  },
  methods: {
    propExists(obj, path) {
      return !!path.split('.').reduce((obj, prop) => {
        return obj && obj[prop] ? obj[prop] : undefined
      }, obj)
    },

    $handleResize() {
      const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

      if (this.$windowWidth !== width) {
        this.$windowWidth = width
      }
    }
  }
}

export default mixins
