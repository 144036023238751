var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      staticStyle: { height: "80px" },
      attrs: {
        gutter: _vm.$gutter,
        type: "flex",
        justify: "end",
        align: "middle"
      }
    },
    [
      _c("el-col", { attrs: { xs: 6, sm: 8 } }, [
        _c("a", { attrs: { href: _vm.siteUrl } }, [
          _c("img", {
            staticClass: "logo",
            attrs: {
              alt: "Owiwi",
              title: "Owiwi",
              src: require("assets/icons/logo.png"),
              srcset:
                require("assets/icons/logo@2x.png") +
                " 2x, " +
                require("assets/icons/logo@3x.png") +
                " 3x"
            }
          })
        ])
      ]),
      _c("el-col", { attrs: { xs: 12, sm: 8 } }),
      _c("el-col", { attrs: { xs: 6, sm: 8 } }, [
        _c(
          "a",
          {
            staticClass: "flex align-center justify-flex-end back-link",
            attrs: { href: _vm.siteUrl }
          },
          [
            _c("i", {
              staticClass: "fa fa-angle-left",
              attrs: { "aria-hidden": "true" }
            }),
            _c("span", [_vm._v("Back to Dashboard")])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }