// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import router from './router/router'
import store from './store/store'
import i18n from './i18n/i18n'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

import VueProgressBar from 'vue-progressbar'

import mixins from '@/mixins/mixins'

import App from '@/App'

import GridContainer from '@/components/GridContainer/GridContainer'

ElementUI.Select.computed.readonly = function() {
  // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode))
  return !(this.filterable || this.multiple || !isIE) && !this.visible
}

Vue.use(ElementUI, { locale })
Vue.use(VueProgressBar, {
  color: '#454b60',
  failedColor: '#874b4b',
  thickness: '3px',
  autoRevert: true,
  location: 'top',
  inverse: false
})

Vue.mixin(mixins)

Vue.component('grid-container', GridContainer)

Vue.prototype.$isProduction = process.env.NODE_ENV === 'production'
Vue.prototype.$isDevelopment = process.env.NODE_ENV === 'development'
Vue.prototype.$isStaging = process.env.NODE_ENV === 'staging'

Vue.config.productionTip = false

/* eslint-disable no-new */
export default new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
