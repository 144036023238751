export default {
  setWindowWidth: (state, width) => {
    state.windowWidth = width
  },

  setUserToken: (state, payload) => {
    state.user.token = payload
  },

  setUserGameId: (state, payload) => {
    state.user.playerResultId = payload
  },

  setGameplay: (state, payload) => {
    state.gameplay = payload
  },

  startGame: state => {
    state.started = true
  },

  langKey: (state, payload) => {
    state.langKey = payload
  }
}
