import axios from '@/axios/axios'
import Cookies from '@/cookies/cookies'
import router from '@/router/router'

export default {
  loginRedirect: ({ commit }, route) => {
    if (route.fullPath.includes('/login')) {
      router.replace('/sjt/start')
    }
    else {
      router.replace(route.fullPath)
    }
  },

  login: ({ commit, dispatch }, payload) => {
    return axios.post('authenticate', payload.data)
      .then(async function(res) {
        const idToken = res.data.id_token

        Cookies.set('id_token', idToken, 1)

        axios.defaults.headers.common.Authorization = 'Bearer ' + idToken
        commit('setUserToken', idToken)

        await dispatch('getGameState')

        await dispatch('getGameplay')

        dispatch('loginRedirect', payload.route)
      })
  },

  tryAutoLogin: async({ commit, dispatch }, payload) => {
    const idToken = Cookies.get('id_token')

    if (!idToken) {
      return false
    }

    axios.defaults.headers.common.Authorization = 'Bearer ' + idToken
    commit('setUserToken', idToken)

    await dispatch('getGameState')

    await dispatch('getGameplay')

    dispatch('loginRedirect', payload.route)
  },

  logout({ commit }, redirect) {
    Cookies.delete('id_token')

    if (redirect) {
      window.location = redirect
    }
    else {
      window.location = '/login'
    }
  },

  getGameState: async({ commit, getters, dispatch }) => {
    if (getters.playerResultId) return Promise.resolve(true)

    const res = await axios.get('game/individual')
    commit('setUserGameId', res.data.id)

    await dispatch('handleLanguage')

    return true
  },

  getGameplay: async({ commit, getters, dispatch }) => {
    if (!getters.isAuthenticated) return Promise.resolve(false)

    if (getters.gameplay) return Promise.resolve(true)

    if (!getters.playerResultId) await dispatch('getGameState')

    if (!getters.langKey) return Promise.resolve(false)

    return axios.get('game/gameplay', {
      params: {
        playerResultId: getters.playerResultId,
        mode: 2
      }
    })
      .then(res => {
        const gameplay = res.data

        if (!gameplay.gameplayProfile.avatarId) {
          dispatch('setAvatar')
        }

        commit('setGameplay', gameplay)
      })
  },

  async handleLanguage({ commit, getters }) {
    const lang = await axios.get('game/language', {
      params: {
        playerResultId: getters.playerResultId
      }
    })

    if (lang.data.langKey) {
      commit('langKey', lang.data.langKey)

      const $i18n = require('@/i18n/i18n').default
      $i18n.locale = lang.data.langKey
    }
  },

  setAvatar: ({ getters }) => {
    const avatars = [
      { id: 1, name: 'Captain Darnica' },
      { id: 2, name: 'Doctor Ramero' },
      { id: 3, name: 'Hildegard' },
      { id: 4, name: 'Wynne the Wayfarer' },
      { id: 5, name: 'Gallante' },
      { id: 6, name: 'Alexandria' },
      { id: 7, name: 'Salvador' },
      { id: 8, name: 'Desiree' }
    ]

    // Select a random avatar to display at dashboard later
    const selectedAvatar = avatars[Math.floor(Math.random() * avatars.length)]

    return axios.post('game/avatar', {
      playerResultId: getters.playerResultId,
      avatarId: selectedAvatar.id,
      avatarName: selectedAvatar.name
    })
  },

  setAnswer: ({ getters }, payload) => {
    return axios.post('game/answer', payload)
  },

  setGameComplete: ({ getters }, payload) => {
    return axios.post('game/complete', payload)
  }
}
