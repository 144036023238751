import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/i18n/locales.js'

Vue.use(VueI18n)
export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: locales
})
