var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize:debounce",
          value: _vm.$handleResize,
          expression: "$handleResize",
          arg: "debounce"
        }
      ],
      staticClass: "app-wrapper"
    },
    [
      _c("vue-progress-bar"),
      _c("el-header", { attrs: { height: "80" } }, [_c("app-header")], 1),
      _c("el-main", [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }