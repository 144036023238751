<template>
  <div
    class="container"
    :class="{
      'form-container': formContainer,
      'no-gutter': noGutter
    }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GridContainer',
  props: {
    formContainer: {
      default: false,
      type: Boolean
    },
    noGutter: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
  @import "~vars";

  .container {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: $-grid-width;
    padding: $-grid-gutter;
  }

  .container.sjt-container { max-width: 1024px; }
  .container.form-container { max-width: 410px; }

  .container.no-gutter { padding: 0; }

  .container:before,
  .container:after {
    content: "";
    display: table;
  }
  .container:after {
    clear: both;
  }

  .container > .container { padding: 0; }
</style>
