const D = process.env.NODE_ENV === 'development' ? '' : ';domain=owiwi.co.uk'

let prefix
switch (process.env.NODE_ENV) {
  case 'production':
    prefix = 'live_'
    break
  case 'staging':
    prefix = 'staging_'
    break
  default:
    prefix = 'local_'
    break
}

const Cookies = {
  set: (cname, cvalue, exdays) => {
    if (typeof exdays === 'undefined') exdays = 1

    const d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + d.toUTCString()
    document.cookie = prefix + cname + '=' + cvalue + ';' + expires + ';path=/' + D
  },
  get: cname => {
    const name = prefix + cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  },
  delete: cname => {
    const d = new Date()
    const expires = 'expires=' + d.toUTCString()
    document.cookie = prefix + cname + '=;' + expires + ';path=/' + D
  }
}

export default Cookies
