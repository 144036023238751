import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'

import { Loading } from 'element-ui'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL, commented out because of this # https://github.com/vuejs/vue-cli/issues/2380
  base: '/', // we live at: https://sjt.owiwi.co.uk,
  routes: [
    { path: '*', redirect: '/login' }, // could be 404 error page

    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      meta: { guest: true },
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login')
    },
    {
      path: '/sjt/start',
      name: 'start',
      meta: { authenticated: true },
      component: () => import(/* webpackChunkName: "sjt" */ '@/views/Start'),
      beforeEnter: async(to, from, next) => {
        if (store.getters.hasStarted) {
          next({ path: '/sjt' })
        }
        else {
          next()
        }
      }
    },
    {
      path: '/sjt',
      name: 'sjt',
      meta: { authenticated: true },
      component: () => import(/* webpackChunkName: "sjt" */ '@/views/SJT'),
      beforeEnter: async(to, from, next) => {
        if (!store.getters.hasStarted) {
          next({ path: '/sjt/start' })
        }
        else if (store.getters.isCompleted) {
          next({ path: '/sjt/completed' })
        }
        else {
          next()
        }
      }
    },
    {
      path: '/sjt/completed/:invitation?',
      name: 'completed',
      meta: { authenticated: true },
      component: () => import(/* webpackChunkName: "sjt" */ '@/views/Completed'),
      beforeEnter: async(to, from, next) => {
        if (
          to.params.invitation === 'invitation' &&
          store.getters.justFinishedInvitation
        ) {
          next()
        }
        else if (store.getters.isCompleted) {
          next()
        }
        else {
          next({ path: '/sjt' })
        }
      }
    }
  ]
})

router.beforeEach(async(to, from, next) => {
  const loader = Loading.service({ fullscreen: true, text: 'Loading your assessment...' })

  // Try auto-login
  if (!store.getters.isAuthenticated) await store.dispatch('tryAutoLogin', { route: to })

  // Init Game
  if (!store.getters.gameplay) await store.dispatch('getGameplay')

  loader.close()

  // Authentication guards
  if (store.getters.isAuthenticated && to.matched.some(record => record.meta.guest)) {
    next({ path: '/sjt/start' })
    return
  }
  else if (!store.getters.isAuthenticated && to.matched.some(record => record.meta.authenticated)) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
    return
  }

  next()
})

export default router
