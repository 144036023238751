<template>
  <el-row style="height: 80px;" :gutter="$gutter" type="flex" justify="end" align="middle">
    <el-col :xs="6" :sm="8">
      <a :href="siteUrl">
        <img class="logo"
          alt="Owiwi"
          title="Owiwi"
          src="~assets/icons/logo.png"
          srcset="~assets/icons/logo@2x.png 2x, ~assets/icons/logo@3x.png 3x"
        />
      </a>
    </el-col>
    <el-col :xs="12" :sm="8"></el-col>
    <el-col :xs="6" :sm="8">
      <a :href="siteUrl" class="flex align-center justify-flex-end back-link">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <span>Back to Dashboard</span>
      </a>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    siteUrl() {
      return process.env.VUE_APP_DASHBOARD_SJT_SCREEN
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~vars";

  .back-link {
    letter-spacing: 0.1px;
    font-weight: 600;
    font-size: $-font-size-s;

    .fa-angle-left {
      font-size: 26px;
      padding-right: 10px;
    }
  }
</style>
