<template>
  <el-container v-resize:debounce="$handleResize" class="app-wrapper">
    <vue-progress-bar></vue-progress-bar>

    <el-header height='80'>
      <app-header></app-header>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import resize from 'vue-resize-directive'

import Header from '@/components/Header/Header'

export default {
  name: 'App',
  directives: {
    resize
  },
  components: {
    'app-header': Header
  },
  methods: {
    initWindowWidth() {
      this.$handleResize()
    }
  },
  created() {
    this.initWindowWidth()
  }
}
</script>

<style src="@/assets/stylesheets/app.scss" lang="scss" ></style>
