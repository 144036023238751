import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      token: null,
      playerResultId: null
    },
    gameplay: null,
    started: null,
    windowWidth: 0,
    langKey: null
  },
  getters,
  mutations,
  actions
})
