import axios from 'axios'
import VueApp from '@/main.js'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API
})

instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.headers.get.accept = 'application/json'

const parseError = error => {
  let errorText = ''

  // The server response was received with a status code that falls out of the range of 2xx
  // OR
  // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  if (error.response || error.request) {
    const mode = error.response ? 'response' : 'request'

    if (!error[mode].data) {
      errorText = error[mode].statusText
    }
    else if (typeof error[mode].data === 'string') {
      errorText = error[mode].data
    }
    else if (error[mode].data.description) {
      errorText = error[mode].data.description
    }
    else if (error[mode].data.message) {
      errorText = error[mode].data.message
    }
    else if (error[mode].data.status) {
      errorText = error[mode].data.status
    }
  }
  else if (error.message) {
    // Something happened in setting up the request that triggered an error
    errorText = error.message
  }
  else {
    errorText = error
  }

  if (
    errorText === '' ||
    (typeof errorText === 'object' && Object.keys(errorText).length === 0)
  ) {
    return 'Something went wrong!'
  }

  // Capitalize first letter
  errorText = (str => {
    if (typeof str !== 'string') return str
    return str.charAt(0).toUpperCase() + str.slice(1)
  })(errorText)

  return errorText
}

instance.throwError = error => Promise.reject(parseError(error))

instance.handleError = error => parseError(error)

instance.errorNotify = error => {
  VueApp.$notify({
    title: VueApp.$t('errors.general.title'),
    message: parseError(error),
    type: 'error'
  })
}

let pendingCalls = 0

// Progress bar loader on ajax request
instance.interceptors.request.use(config => {
  if (!pendingCalls) {
    VueApp.$Progress.start()
  }
  pendingCalls++
  return config
})

instance.interceptors.response.use(res => {
  if (--pendingCalls === 0) {
    VueApp.$Progress.finish()
  }
  return res
}, error => {
  // Show failed progress bar regardless if its the last call
  pendingCalls--
  VueApp.$Progress.fail()

  switch (error.response.status) {
    case 400:
    case 401:
    case 404:
    case 500:
      if (VueApp.$isProduction) {
        VueApp.$ga.event('SJT Exception', 'Error', error.response.data.message)
      }

      window.location = process.env.VUE_APP_DASHBOARD_SJT_SCREEN
  }

  return Promise.reject(error)
})

export default instance
